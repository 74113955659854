import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';

export default function Button({ text, link, large, external, className }) {
  return (
    <div
      className={(large) ? `type--45 ${className}` : `type--20 ${className}`}
      css={css`
        text-align: center;
      `}
    >
      {external && <a
        css={css`
          display: inline-block;
          text-decoration: none;
          padding: ${(large) ? '1.5rem 4rem 1.1rem' : '0.9rem 2rem 0.5rem'};
          border: 2px solid var(--black);
          margin: var(--margin) 0;
          transition: color 0.6s var(--curve), background-color 0.6s var(--curve);

          &:hover {
            color: var(--black);
            background-color: var(--primary);
            transition: color 0s, background-color 0s;
          }
        `}
        target="_blank"
        rel="noreferrer"
        href={link}
      >{text}</a>}
      {!external && <Link
        css={css`
          display: inline-block;
          text-decoration: none;
          padding: 0.9rem 2rem 0.5rem;
          padding: ${(large) ? '1.5rem 4rem 1.1rem' : '0.9rem 2rem 0.5rem'};
          border: 2px solid var(--black);
          margin: var(--margin) 0;
          transition: color 0.6s var(--curve), background-color 0.6s var(--curve);

          &:hover {
            color: var(--black);
            background-color: var(--primary);
            transition: color 0s, background-color 0s;
          }
        `}
        to={link}
      >{text}</Link>}
    </div>
  )
}
